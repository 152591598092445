<template>
  <v-container fluid class="full-height pa-0">
    <v-row class="fill-height">
      <v-col
        cols="6"
        class="pl-12 pr-12"
      >
      <div class="d-flex justify-center flex-column" style="height: 100%;">

        <div class="my-3" style=" height: 150px;">

        <img
          :src="require('../assets/logo.png')"
          class="my-3"
          style="max-height: 150px;"
        />
        <h1 class="font-weight-bold headline">
          Lovačko društvo Jarebica
        </h1>
        </div>
        <div class="inputs-container">
        <v-form @submit.prevent="loginUser">

        <v-text-field
          dense
          outlined
          label="Email adresa"
          width="200"
          v-model="form.email_address"
        ></v-text-field>
        <v-text-field
          dense
          outlined
          v-model="form.password"
          label="Password"
          :append-icon="formOptions.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="formOptions.showPassword ? 'text' : 'password'"
          @click:append="formOptions.showPassword = !formOptions.showPassword"
        />
        <v-btn color="primary" type="submit" block class="mt-4 text-capitalize letter-spacing-normal" @click="loginUser">
          <v-progress-circular
                  indeterminate
                  :size="24"
                  :width="2"
                  color="white"
                  v-if="loggingIn"
                ></v-progress-circular>
                <span class="text--white font-weight-bold" v-else
                  >Prijava
                </span></v-btn>
        </v-form>
        </div>
      </div>
      </v-col>

      <v-col
        cols="6" class="background">

      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import Api from "../services/Api";
export default {
  name: 'Login',
  methods: {
    ...mapActions({"login": "user/login", "addAlert": "global/addAlert"}),
    loginUser() {
      this.login(this.form).then(() => {
        Api.reinitializeToken()
        this.$router.replace('/')
      }).catch(err => {
        let { error } = err.response.data;
        this.addAlert({
          "type": "error",
          "message": error
        })
      });
    }
  },
  computed: {
    ...mapGetters({
      "loggingIn": "user/loggingIn",
    })
  },
  data: () => ({
    form: {
      email_address: "",
      password: "",
    },
    formOptions: {
      showPassword: false,
    },
  }),
};
</script>

<style lang="scss" scoped>
.background {
  background: url('../assets/login_splash.jpg') center no-repeat;
  background-size: cover;
  height: 100vh;
}
.inputs-container {
  margin-top: 64px;
  width: 400px;
}
</style>
